<template>
    <div class="container">
      <h3>Datasets</h3>

      <router-link to="/home/datasets/list">
        <div class="rebutton-icon">
          <div class="icon">
            <font-awesome-icon icon="database" size="1x" />
          </div>
          MY DATASETS
        </div>
      </router-link>

      <router-link to="/home/datasets/load">
        <div class="rebutton-icon">
          <div class="icon" >
            <font-awesome-icon icon="database" size="1x" />
          </div>
          LOAD NEW DATASET
        </div>
      </router-link>

<!--        <router-link :to="'/home/datasets/list'">-->
<!--          <font-awesome-icon :icon="['fal', 'file-search']" size="6x" />-->
<!--          <div class="dashboard_name">-->
<!--            <h3>List Datasets</h3>-->
<!--          </div>-->
<!--        </router-link>-->
<!--      </div>-->
<!--      <div class="dashboard_preview">-->
<!--        <router-link :to="'/home/datasets/load'">-->
<!--          <font-awesome-icon :icon="['far', 'layer-plus']" size="6x" />-->
<!--          <div class="dashboard_name">-->
<!--            <h3>Load Dataset</h3>-->
<!--          </div>-->
<!--        </router-link>-->
<!--      </div>-->
  </div>
</template>

<script>
export default {};
</script>

<style scoped>

.container {
  padding: 40px;
}

.dashboard_listing {
  background-color: #e9ecef;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.dashboard_preview {
  width: 240pt;
  min-height: 200pt;
  background-color: grey;
  margin: 8pt;
  padding: 16pt;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.dashboard_preview > a {
  margin-top: auto; /* pushes to bottom */
  color: white;
}
.dashboard_preview > a:hover {
  color: #0056b3;
  text-decoration: none;
}

h3 {
  font-size: 32pt;
}

.dashboard_name {
  min-height: 64pt;
}

.front_messaging {
  margin: auto;
  text-align: center;
}
</style>
